const en_US = {
    hello: 'Hello, world!',
    name: 'my name is {name}',
    'common.year': 'years',
    'common.month': 'months',
    'common.day': ' days',
    'common.hour': ' hours',
    'common.minute': ' minutes',
    'nav.block': 'Blocks',
    'nav.masternode': 'Masternodes',
    'nav.login': 'Log in',
    'nav.signup': 'Sign Up',
    'nav.logout': 'Log out',
    'nav.modify_passwd': 'Change Password',
    'nav.usercenter': 'User Center',
    'nav.useraddress': 'Address Monitor',
    'nav.usermasternode': 'Masternodes Monitor',
    'nav.vid_system': 'VID System',
    'nav.rich': 'Rich List',
    'home.marketing': 'Marketing',
    'home.season': 'Season',
    'home.current_season': 'Current Season',
    'home.left_blocks': 'Left Blocks',
    'home.end_blocks': 'Season Last Block',
    'home.end_time': 'Expected End Time',
    'home.vid_count': 'VID Invited Count',
    'home.total': 'Total',
    'home.block_height': 'Block Height',
    'home.exchange_rate': 'Btc Exchange Rate',
    'home.rmb_price': 'RMB Price',
    'home.usd_price': 'USD Price',
    'home.latest_block': 'Latest Blocks',
    'home.height': 'Height',
    'home.age': 'Age',
    'home.transactions': 'Transactions',
    'home.size': 'Size',
    'home.see_all_blocks': 'See All Blocks',
    'home.robot_tip1': ' is a vds robot on telegram. Currently you can monitor change of wallet balance and masternode status. More features are in development, please follow the website and CuckooVollarBot.',
    'home.robot_tip2': '1、Signup and log in on this website, view your token on user center page.',
    'home.robot_tip3': '2、Search "CuckooVollarBot" on telegram, click "Start", follow the prompts to bind your token.',
    'home.robot_tip4': '3、Add wallet or masternode address on this website, the system will push messages on telegram when necessary',
    'home.vid_rank_of_season': 'VID Weight Rank Of This Season',
    'home.vid_rank_of_last_season': 'VID Weight Rank Of Last Season',
    'home.rank': 'Rank',
    'home.address': 'Address',
    'home.invited_amount': 'Invited Amount',
    'home.weight': 'Weight',
    'home.latest_transaction': 'Latest Transactions',
    'home.coin_supply': 'Coin Supply',
    'home.circulating_supply': 'Circulating Supply',
    'home.total_supply': 'Total Supply',
    'home.burn_total': 'Migration Total',
    'home.mine_status': 'Mining Status',
    'home.difficulty': 'Difficulty',
    'home.total_hashrate': 'Total Hashrate',
    'home.next_block_reward': 'Expected Block Reward Of Next Season',
    'home.block_miner_reward': 'Miner Reward',
    'home.block_mn_reward': 'Masternode Reward',
    'block.blocks': 'Blocks',
    'block.timestamp': 'Timestamp',
    'block.height': 'Height',
    'block.age': 'Age',
    'block.transactions': 'Transactions',
    'block.transaction': 'Transactions',
    'block.size': 'Size',
    'block.block': 'Block',
    'block.hash': 'Block Hash',
    'block.summary': 'Summary',
    'block.number_of_transactions': 'Number of Transactions',
    'block.difficulty': 'Difficulty',
    'block.bits': 'Bits',
    'block.block_reward': 'Block Reward',
    'block.version': 'Version',
    'block.mined_by': 'Mined by',
    'block.masternode': 'Masternode by',
    'block.nonce': 'Nonce',
    'block.merkle_root': 'Merkle Root',
    'block.confirmations': 'Confirmations',
    'block.mined': 'mined',
    'block.reward': 'Reward',
    'block.choose_date': 'Choose Date:',
    'block.miner': 'Miner',
    'masternode.masternodes': 'Masternodes',
    'masternode.total': 'Total',
    'masternode.address': 'Address',
    'masternode.status': 'Status',
    'masternode.last_seen': 'Last seen',
    'masternode.active_seconds': 'Active seconds',
    'masternode.balance': 'Balance(Vollar)',
    'masternode.ENABLED': 'ENABLED',
    'masternode.NEW_START_REQUIRED': 'NEW_START_REQUIRED',
    'masternode.PRE_ENABLED': 'PRE_ENABLED',
    'masternode.has_reward': 'Has Reward',
    'masternode.error': 'Error',
    'masternode.daily_status': 'Status Record ',
    'masternode.no_record': 'No record',
    'masternode.time': 'Time',
    'masternode.before': 'Before',
    'masternode.after': 'After',
    'masternode.active_time': 'Active Time',
    'masternode.income': 'Total Income',
    'masternode.last_paid_time': 'Last Paid Time',
    'masternode.search_masternode_text': 'Input Masternode Address',
    'masternode.current_state': 'Current State',
    'masternode.history_trend': 'History Trend',
    'transaction.txid': 'TxId',
    'transaction.time': 'Time',
    'transaction.type': 'Type',
    'transaction.value': 'Value',
    'transaction.transaction': 'Transaction',
    'transaction.hash': 'Transaction Hash',
    'transaction.summary': 'Summary',
    'transaction.size': 'Size',
    'transaction.fee_rate': 'Fee Rate',
    'transaction.confirmations': 'Confirmations',
    'transaction.received_time': 'Received Time',
    'transaction.mined_time': 'Mined Time',
    'transaction.included_in_block': 'Included in Block',
    'transaction.details': 'Details',
    'transaction.mined': 'mined',
    'transaction.type': 'Type',
    'transaction.vid_tx': 'Clue Transaction',
    'transaction.normal_tx': 'Normal Transaction',
    'transaction.coinbase_tx': 'Coinbase Transaction',
    'transaction.gas_refund': 'Gas Refund',
    'transaction.contract_type_create': 'Contract Create',
    'transaction.contract_type_call': 'Contract Call',
    'transaction.voucher': 'Voucher',
    'transaction.new_generated': 'New Generated',
    'address.address': 'Address',
    'address.summary': 'Summary',
    'address.balance': 'Balance',
    'address.confirmed_alance': 'Confirmed Balance',
    'address.unconfirmed_alance': 'Unconfirmed Balance',
    'address.transactions': 'Nro.Transactions',
    'address.transaction': 'Transactions',
    'address.vid_info': 'Invited Vid Amount',
    'address.invited_season': 'This Season',
    'address.invited_total': 'Total',
    'coin.type0': 'Normal',
    'coin.type1': 'Clue',
    'coin.type2': 'Mine Reward',
    'coin.type3': 'Masternode Reward',
    'coin.type4': 'Ad',
    'coin.type5': 'Tandia',
    'coin.type6': 'Contract',
    'coin.type7': 'Vib',
    'coin.type8': 'Bid',
    'user.user_center': 'User Center',
    'user.address_monitor_list': 'Address Monitor List',
    'user.add_address': 'Add Address',
    'user.index': 'Index',
    'user.address': 'Address',
    'user.notes': 'Notes',
    'user.action': 'Action',
    'user.edit': 'Edit',
    'user.delete': 'Delete',
    'user.masternode_monitor_list': 'Masternode Monitor List',
    'user.add_masternode': 'Add Masternode',
    'user.masternode_count': 'Masternode Count',
    'user.status': 'Status',
    'user.edit_address': 'Edit Address',
    'user.login': 'Login',
    'user.register': 'Sign Up',
    'user.modify_passwd': 'Change Password',
    'user.save': 'Save',
    'user.bind': 'Bind',
    'user.bind_vid': 'Bind VID',
    'user.my_invite_vid': 'My Invited VID User',
    'user.empty': 'No data',
    'user.no_vid_tip': 'You have not binded VID address.',
    'user.vid_tip1': 'How to bind VID?',
    'user.vid_tip2': '1.Sign the message "I want to bind vid" with VDS official wallet APP, and you will get signed signature.',
    'user.vid_tip3': '2.Fill in the form with your VID address and signature.',
    'user.vid_tip4': '3.Submit this form.',
    'user.label_vid_address': "Vid Address",
    'user.label_message': "Message",
    'user.label_signature': "Signature",
    'user.search_masternode_text': 'Input address or note',
    'pool.title': 'Pool Data',
    'pool.name': 'Pool',
    'pool.hashrate': 'Pool Hashrate',
    'pool.average': 'Pool Average Reward',
    'pool.hashrate_percentage': 'Pool Hashrate Percentage',
    'verify.title': "Verify Message",
    'verify.submit': 'Verify',
    'verify.input_error': 'Input is invalid, please check your input',
    'verify.pass': 'Verification passed',
    'verify.fail': 'Verification failed',
    'broadcast.title': 'Broadcast',
    'broadcast.success': 'Send Transaction Succeed',
    'broadcast.fail': 'Send Transaction Failed',
    'broadcast.error': 'Connect error. Try again',
    'broadcast.raw_tx': 'Transaction Raw Data',
    'broadcast.submit': 'Send',
    'token.name': 'Name',
    'token.supply': 'Total Supply',
    'token.contract_address': 'Contract Address',
    'token.transactions': '	TX Count',
    'token.holders': 'Token Holders',
    'token.sender': 'Sender',
    'token.receiver': 'Receiver',
    'token.amount': 'Amount',
    'contract.transactionCount': 'Transaction Count',
    'contract.totalReceived': 'Total Received',
    'contract.totalSent': 'Total Sent',
    'contract.balance': 'Balance',
    'contract.sender': 'Caller',
    'contract.gasUsed': 'Gas Used',
}
export default en_US;