const zh_CN = {
    hello: '你好，世界！',
    name: '我的名字是 {name}',
    'common.year': '年',
    'common.month': '月',
    'common.day': '天',
    'common.hour': '小时',
    'common.minute': '分钟',
    'nav.block': '区块',
    'nav.masternode': '主节点',
    'nav.login': '登录',
    'nav.signup': '注册',
    'nav.logout': '退出',
    'nav.modify_passwd': '修改密码',
    'nav.usercenter': '个人中心',
    'nav.useraddress': '地址监控',
    'nav.usermasternode': '主节点监控',
    'nav.vid_system': 'VID体系',
    'nav.rich': '富豪榜',
    'nav.token': '代币',
    'home.marketing': '行情',
    'home.season': '赛季',
    'home.current_season': '当前赛季',
    'home.left_blocks': '剩余区块',
    'home.end_blocks': '本赛季截止区块',
    'home.end_time': '预计结束时间',
    'home.vid_count': 'VID邀请数',
    'home.total': '总计',
    'home.block_height': '区块高度',
    'home.exchange_rate': '共振兑换比例',
    'home.rmb_price': '人民币价格',
    'home.usd_price': '美元价格',
    'home.latest_block': '最新区块',
    'home.height': '高度',
    'home.age': '时间',
    'home.transactions': '交易数',
    'home.size': '大小',
    'home.see_all_blocks': '查看所有区块',
    'home.robot_tip1': '是一个VDS电报机器人，现在你可以通过它监控VDS钱包余额变化、主节点状态及余额变化，更多的功能还在开发中，请关注本网站和机器人通知。',
    'home.robot_tip2': '1、注册本网站账号，在个人中心页面查看分配的token',
    'home.robot_tip3': '2、在电报搜索CuckooVollarBot，点击start，按照提示绑定token',
    'home.robot_tip4': '3、在本网站地址监控和主节点监控页面添加需要监控的地址，当有相应变化时会自动为你推送电报消息',
    'home.vid_rank_of_season': '本赛季VID权重排行榜',
    'home.vid_rank_of_last_season': '上赛季VID权重排行榜',
    'home.rank': '排名',
    'home.address': '地址',
    'home.invited_amount': '直接邀请人数',
    'home.weight': '权重',
    'home.latest_transaction': '最新交易',
    'home.coin_supply': '供应量',
    'home.circulating_supply': '流通量',
    'home.total_supply': '总量',
    'home.burn_total': '迁移量',
    'home.mine_status': '挖矿',
    'home.difficulty': '难度',
    'home.total_hashrate': '全网算力',
    'home.next_block_reward': '下赛季预计区块奖励',
    'home.block_miner_reward': '挖矿奖励',
    'home.block_mn_reward': '主节点奖励',
    'block.blocks': '区块',
    'block.timestamp': '时间',
    'block.height': '高度',
    'block.age': '时间',
    'block.transactions': '交易数',
    'block.transaction': '交易',
    'block.size': '大小',
    'block.block': '区块',
    'block.hash': '区块哈希',
    'block.summary': '概要',
    'block.number_of_transactions': '交易数',
    'block.difficulty': '难度',
    'block.bits': '比特',
    'block.block_reward': '区块奖励',
    'block.version': '版本',
    'block.mined_by': '矿工',
    'block.masternode': '主节点',
    'block.nonce': '随机数',
    'block.merkle_root': 'Merkle Root',
    'block.confirmations': '确认数',
    'block.mined': '出块',
    'block.reward': '奖励',
    'block.choose_date': '选择日期：',
    'block.miner': '挖出者',
    'masternode.masternodes': '主节点',
    'masternode.total': '总数',
    'masternode.address': '地址',
    'masternode.status': '状态',
    'masternode.last_seen': '最近活跃时间',
    'masternode.active_seconds': '在线时长（秒）',
    'masternode.balance': '余额(Vollar)',
    'masternode.ENABLED': '运行中',
    'masternode.NEW_START_REQUIRED': '需重启',
    'masternode.PRE_ENABLED': '待运行',
    'masternode.has_reward': '有奖励',
    'masternode.error': '异常',
    'masternode.daily_status': '状态变化记录',
    'masternode.no_record': '没有记录',
    'masternode.time': '时间',
    'masternode.before': '变化前',
    'masternode.after': '变化后',
    'masternode.active_time': '在线时长',
    'masternode.income': '总收入',
    'masternode.last_paid_time': '上次奖励时间',
    'masternode.search_masternode_text': '输入主节点地址',
    'masternode.current_state': '当前状态',
    'masternode.history_trend': '历史趋势',
    'transaction.txid': '交易Id',
    'transaction.time': '时间',
    'transaction.type': '类型',
    'transaction.value': '值',
    'transaction.transaction': '交易',
    'transaction.hash': '哈希',
    'transaction.summary': '概要',
    'transaction.size': '大小',
    'transaction.fee_rate': '费率',
    'transaction.confirmations': '确认数',
    'transaction.received_time': '接收时间',
    'transaction.mined_time': '出块时间',
    'transaction.included_in_block': '区块',
    'transaction.details': '详情',
    'transaction.mined': '出块',
    'transaction.type': '类型',
    'transaction.vid_tx': '裂变交易',
    'transaction.normal_tx': '普通交易',
    'transaction.coinbase_tx': 'Coinbase交易',
    'transaction.gas_refund': 'Gas退款',
    'transaction.contract_type_create': '合约创建',
    'transaction.contract_type_call': '合约调用',
    'transaction.voucher': '票据',
    'transaction.new_generated': '新产生',
    'address.address': '地址',
    'address.summary': '概要',
    'address.balance': '余额',
    'address.confirmed_alance': '已确认',
    'address.unconfirmed_alance': '待确认',
    'address.transactions': '交易数',
    'address.transaction': '交易列表',
    'address.vid_info': 'Vid邀请数',
    'address.invited_season': '本赛季',
    'address.invited_total': '总计',
    'coin.type0': '普通交易',
    'coin.type1': '裂变交易',
    'coin.type2': '挖矿奖励',
    'coin.type3': '主节点奖励',
    'coin.type4': '广告交易',
    'coin.type5': '投票交易',
    'coin.type6': '合约交易',
    'coin.type7': '共振交易',
    'coin.type8': '竞价交易',
    'user.user_center': '个人中心',
    'user.address_monitor_list': '地址监控列表',
    'user.add_address': '添加地址',
    'user.index': '序号',
    'user.address': '地址',
    'user.notes': '备注',
    'user.action': '操作',
    'user.edit': '编辑',
    'user.delete': '删除',
    'user.masternode_monitor_list': '主节点监控列表',
    'user.add_masternode': '添加主节点',
    'user.masternode_count': '主节点数量',
    'user.status': '状态',
    'user.edit_address': '编辑地址',
    'user.login': '登录',
    'user.register': '注册',
    'user.modify_passwd': '修改密码',
    'user.save': '保存',
    'user.bind': '绑定',
    'user.bind_vid': '绑定VID',
    'user.my_invite_vid': '我邀请的VID用户',
    'user.empty': '暂无',
    'user.no_vid_tip': '你还没有绑定VID地址，',
    'user.vid_tip1': '怎样绑定VID?',
    'user.vid_tip2': '1、使用VDS官方钱包将消息进行签名，你会得到一个签名后的字符串',
    'user.vid_tip3': '2、把你的VID地址和签名后字符串填入表单',
    'user.vid_tip4': '3、提交表单',
    'user.search_masternode_text': '输入地址或备注',
    'user.label_vid_address': "Vid地址",
    'user.label_message': "消息",
    'user.label_signature': "签名",
    'pool.title': '矿池数据',
    'pool.name': '矿池',
    'pool.hashrate': '矿池算力走势',
    'pool.average': '矿池24小时平均收益',
    'pool.hashrate_percentage': '矿池当前算力占比',
    'verify.title': "验证消息",
    'verify.submit': '验证',
    'verify.input_error': '验证失败，请检查你的输入',
    'verify.pass': '验证通过',
    'verify.fail': '验证不通过',
    'broadcast.title': '广播交易',
    'broadcast.success': '交易发送成功',
    'broadcast.fail': '交易发送失败',
    'broadcast.error': '连接错误，请重试',
    'broadcast.raw_tx': '交易数据',
    'broadcast.submit': '发送',
    'token.name': '名称',
    'token.supply': '发行量',
    'token.contract_address': '合约地址',
    'token.transactions': '交易数',
    'token.holders': '持有地址数',
    'token.sender': '发送方',
    'token.receiver': '接收方',
    'token.amount': '交易额',
    'contract.transactionCount': '交易数',
    'contract.totalReceived': '转入总计',
    'contract.totalSent': '转出总计',
    'contract.balance': '余额',
    'contract.sender': '调用方',
    'contract.gasUsed': 'Gas使用量',

}
export default zh_CN;