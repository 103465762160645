import axios from 'axios';
import Immutable from 'immutable';
import{ ITEMS_EVERY_PAGE, API_URL } from '../../conf'

const FETCH_MARKET_PRICE = 'FETCH_MARKET_PRICE';
const FETCH_VDS_SUPPLY = 'FETCH_VDS_SUPPLY';
const FETCH_MINE_STATS = 'FETCH_MINE_STATS';
const FETCH_RICHES = 'FETCH_RICHES';
const FETCH_VDS_INFO = 'FETCH_VDS_INFO';

const apiUrl = '/koa';


export const fetchVdsPrice = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/vds_price`)
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchVdsPriceSuccess(res['price']));
                } else {
                    //message.error(res.message);
                }
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchVdsPriceSuccess = (price) => {
    return {
        type: FETCH_MARKET_PRICE,
        price
    };
};

export const fetchVdsSupply = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/vds_supply`)
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchVdsSupplySuccess(res['result']));
                } else {
                    //message.error(res.message);
                }
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchVdsSupplySuccess = (data) => {
    return {
        type: FETCH_VDS_SUPPLY,
        data
    };
};

export const fetchVdsMineStats = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/vds_mine_stats`)
            .then(response => {
                let res = response.data;
                    
                dispatch(fetchVdsMineStatsSuccess(res['nodes'][0]['difficulty']));

            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchVdsMineStatsSuccess = (difficulty) => {
    return {
        type: FETCH_MINE_STATS,
        difficulty
    };
};

export const fetchRiches = () => {
    return (dispatch) => {
        return axios.get(`${API_URL}/misc/rich-list`)
            .then(response => {
                let res = response.data;
                dispatch(fetchRichesSuccess(res['list']));
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchRichesSuccess = (riches) => {
    return {
        type: FETCH_RICHES,
        riches
    };
};

export const fetchVdsInfo = () => {
    return (dispatch) => {
        return axios.get(`${API_URL}/vds-info`)
            .then(response => {
                let res = response.data;
                dispatch(fetchVdsInfoSuccess(res));
               
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchVdsInfoSuccess = (data) => {
    return {
        type: FETCH_VDS_INFO,
        data
    };
};

let initMarketState = Immutable.fromJS({
    price: {},
    supply: 0,
    estimateYield: 0,
    difficulty: 0,
    riches: [],
    info: {
        burnTotal: 0
    }
});

const marketReducer = (state = initMarketState, action) => {
    switch (action.type) {
        case FETCH_MARKET_PRICE:
            return state.set('price', Immutable.fromJS(action.price));
        case FETCH_VDS_SUPPLY:
            return state.set('supply', action.data.MarketCirculation).set('estimateYield', action.data.EstimateYield);
        case FETCH_MINE_STATS:
            return state.set('difficulty', action.difficulty);
        case FETCH_RICHES:
            return state.set('riches', Immutable.fromJS(action.riches));
        case FETCH_VDS_INFO:
            return state.set('info', Immutable.fromJS(action.data));
        default:
            return state;
    }
}
export default marketReducer;