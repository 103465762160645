var bs58check = require('bs58check');

function addAmountDelimiters(string) {
    return string.replace(/^(\d{1,3})((\d{3})*)(\.\d+|)$/g, (_, before, middle, __, after) => {
      return before + middle.replace(/(\d{3})/g, ',$1') + after
    })
}

export function formatHashrate(hashrate) {
    var hashrate = hashrate||0;
    var i = 0;
    var units = ['Sol', 'KSol', 'MSol', 'GSol', 'TSol', 'PSol'];
    while (hashrate > 1000) {
      hashrate = hashrate / 1000;
      i++;
    }
    return hashrate.toFixed(2) + ' ' + units[i];
}

export function formatDifficulty(hashrate) {
    var n = hashrate||0;

    if (n < 1000) {
        return n;
    }

    var i = 0;
    var units = ['K', 'M', 'G', 'T', 'P'];
    while (n > 1000) {
        n = n / 1000;
        i++;
    }
    return n.toFixed(3) + ' ' + units[i - 1];
}

export function computeHashrate(e = 0, t = 60) {
    e = 32 * e / t;
    var n = "KH/s"
        , i = Math.pow(10, 3);
    return e > Math.pow(10, 12) ? (n = "TH/s",
        i = Math.pow(10, 12)) : e > Math.pow(10, 9) ? (n = "GH/s",
        i = Math.pow(10, 9)) : e > Math.pow(10, 6) && (n = "MH/s",
        i = Math.pow(10, 6)),
        (e / i).toFixed(3) + " " + n
}

export function formatNumber(n) {
    return parseInt(n).toLocaleString();
}

export function randomString(len, charSet) {
    charSet = charSet || 'ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz023456789';
    var randomString = '';
    for (var i = 0; i < len; i++) {
    	var randomPoz = Math.floor(Math.random() * charSet.length);
    	randomString += charSet.substring(randomPoz,randomPoz+1);
    }
    return randomString;
}

export function computeSesaon(blockHeight = 0) {
    const firstSeasonBlocks = 1440 * 7 * 3;
    const blocksPerSeason = 1440 * 7;
    const season = blockHeight < firstSeasonBlocks ? 1 : Math.ceil((blockHeight - firstSeasonBlocks)/blocksPerSeason) + 1;
    
    return season;
}

/**
    * 将vds地址转换为btc地址
    *
    * @param vdsAddress
    * @return
*/
export function convertVdsAddressToBtcAddress(vdsAddress) {
    var bytes = bs58check.decode(vdsAddress);
    var tmp = bytes.slice(2);

    var mainP2PKHVersion = new Uint8Array([0]);
    var mainP2PKHVersionAddressBytes = new Uint8Array(mainP2PKHVersion.length+tmp.length);
    mainP2PKHVersionAddressBytes.set(mainP2PKHVersion);
    mainP2PKHVersionAddressBytes.set(tmp, mainP2PKHVersion.length);

    var mainP2PKHVersionAddress = bs58check.encode(Buffer.from(mainP2PKHVersionAddressBytes));

    return mainP2PKHVersionAddress
}

export function formatTokenSupply (amount, decimals = 0, showDecimals = false)  {
    if (decimals === 0) {
      return amount
    }
    let s = amount.toString().padStart(decimals + 1, '0')
    let integralPart = s.slice(0, -decimals)
    let decimalPart = s.slice(-decimals)
    let value = addAmountDelimiters(integralPart + '.' + decimalPart)
    // if (showDecimals) {
    //     return value
    //   } else {
    //     return value.replace(/\.?0*$/g, '')
    //   }
    if (showDecimals) {
      return value.replace(/\.?0*$/g, '')
    } else {
      return addAmountDelimiters(integralPart)
    }
}

export function groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
};